<template>
  <div>
    <table-wrapper
      :table-header="headers"
      no-filters
      :fetch-data="fetchData"
      :rows="rows"
      :list-length="feedbackUser.length"
      :pager="pager"
      :page-sizes="pageSizes"
      :data-size="3"
      :change-page-size="changePageSize"
      :is-loading="isLoading"
    >
      <template #body>
        <feedback-user-info
          v-for="feedbackInfo in feedbackUser"
          :key="feedbackInfo.info.feedbackName"
          :feedback-user="feedbackInfo"
          :open-modal-share="openModalShare"
          :open-modal-note="openModalNote"
          :small-table="smallTable"
          statuses="feedback"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="mb-4">
              <em>
                <strong>
                  Nessun feedback presente
                </strong>
              </em>
            </p>
          </div>
        </div>
      </template>
    </table-wrapper>
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty } from '@/utils/validators';

const FeedbackUserInfo = () => import('@/components/userSections/FeedbackUserInfo.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');

export default {
  name: 'FeedbackList',
  components: { LoaderInfo, FeedbackUserInfo, TableWrapper },
  props: {
    openModalShare: Function,
    openModalNote: Function,
    isLoading: Boolean,
    smallTable: Boolean,
    entityType: String,
    entityId: String,
    withResponseOnly: Boolean,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 3,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [],
      },
      submitted: false,
      customFilters: false,
    };
  },
  computed: {
    feedbackUser() {
      return this.$store.getters['score/feedbackUserList']();
    },
    hasFeedbackUser() {
      return this.$store.getters['score/feedbackUserList'].length > 0;
    },
    pager() {
      return this.$store.getters['score/feedbackUserPageable']();
    },
    rows() {
      return this.pager.totalElements;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    headers() {
      if (this.smallTable) {
        return [
          {
            classes: 'white-space-nowrap',
            content: 'Codice',
          },
          {
            classes: 'white-space-nowrap',
            content: 'Date',
          },
          {
            classes: 'w-100',
            content: 'Richiesta',
          },
          {
            classes: 'white-space-nowrap',
            content: 'Tot. feedback',
          },
        ];
      }
      return [
        {
          classes: 'table-cell--compact',
          content: 'Stato',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Codice',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Date',
        },
        {
          classes: 'w-100',
          content: 'Richiesta',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Feedback',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Tot. feedback',
        },
        {
          classes: 'table-cell--compact',
          content: '',
        },
      ];
    },
    pageSizes() {
      return [
        { value: 3, text: '3' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
      ];
    },
  },
  created() {
    // console.log('list created');
    if (this.withResponseOnly) {
      this.searchBase.filters.push(
        {
          field: 'status',
          operation: 'EQ',
          value: 'CLOSE',
        },
      );
    }
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      console.log('list fectad', this.searchBase, nextPage);
      // const paging = this.$store.getters['subject/investmentPageable'];
      // const search = { ...this.searchBase, ...paging };
      const search = { ...this.searchBase };
      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['score/feedbackUserPageable']();
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      const currentEntityType = this.entityType || (this.isCompanyProfile ? 'company' : 'person');
      const currentEntityId = this.entityId || (this.isCompanyProfile ? this.$store.getters['subject/currentCompany'].companyId : null);
      this.$store.dispatch('score/searchFeedbackUser', {
        entityType: currentEntityType, entityId: currentEntityId, search, reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    changePageSize(size) {
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
  },
};
</script>

<style scoped>

</style>
